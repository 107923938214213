import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)<{ white?: boolean }>`
    padding: 16px 28px;
    gap: 12px;
    background-color: ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.blue5)};
    color: ${({ theme, white }) => (white ? theme.colors.blue5 : theme.colors.gray1)};
    transition: background-color 0.3s ease-in-out;
    text-transform: capitalize;
    border-radius: 10px;

    > svg {
        flex-shrink: 0;
    }

    :hover {
        background-color: ${({ theme, white }) =>
            white ? theme.colors.blue1 : theme.colors.blue6};
    }

    :focus-visible {
        background-color: ${({ theme, white }) =>
            white ? theme.colors.blue1 : theme.colors.blue6};
    }
`;
