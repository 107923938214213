import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)<{ white?: boolean }>`
    padding: 14px 26px;
    gap: 12px;
    color: ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.blue5)};
    transition:
        background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
    text-transform: capitalize;
    border-radius: 10px;
    border: 2px solid ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.blue5)};
    font-weight: 600;

    > svg {
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    :hover {
        color: ${({ theme, white }) => (white ? theme.colors.blue2 : theme.colors.blue5)};
        background-color: ${({ white }) =>
            white ? 'rgba(255, 255, 255, 0.40)' : 'rgba(204, 217, 229, 0.50)'};
        border-color: ${({ theme, white }) => (white ? theme.colors.blue2 : theme.colors.blue5)};

        > svg {
            path {
                fill: ${({ theme, white }) => (white ? theme.colors.blue2 : theme.colors.blue5)};
            }
        }
    }

    :focus-visible {
        color: ${({ theme, white }) => (white ? theme.colors.blue2 : theme.colors.blue5)};
        background-color: ${({ white }) =>
            white ? 'rgba(255, 255, 255, 0.40)' : 'rgba(204, 217, 229, 0.50)'};
        border-color: ${({ theme, white }) => (white ? theme.colors.blue2 : theme.colors.blue5)};

        > svg {
            path {
                fill: ${({ theme, white }) => (white ? theme.colors.blue2 : theme.colors.blue5)};
            }
        }
    }
`;
