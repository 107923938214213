import { css } from '@emotion/react';

export const h1 = css`
    font-size: 2.5rem;
    line-height: 122%;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 5.5rem;
    }
`;

export const h2 = css`
    font-size: 2rem;
    line-height: 112.5%;
    font-weight: 600;

    @media (min-width: 1280px) {
        font-size: 4rem;
        line-height: 106.25%;
    }
`;

export const h3 = css`
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 150%;

    @media (min-width: 1280px) {
        line-height: 130%;
        font-size: 2.5rem;
    }
`;

export const h4 = css`
    font-size: 1.25rem;
    line-height: 140%;
    font-weight: 600;

    @media (min-width: 1280px) {
        line-height: 162.5%;
        font-size: 2rem;
    }
`;

export const h5 = css`
    font-size: 1.125rem;
    line-height: 140%;
    font-weight: 600;

    @media (min-width: 1280px) {
        line-height: 216.67%;
        font-size: 1.5rem;
    }
`;
export const smTxt = css`
    font-size: 0.875rem;

    @media (min-width: 1280px) {
        font-size: 1rem;
    }
`;

export const lgTxt = css`
    font-size: 1.125rem;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;
