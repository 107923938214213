import { CLink } from '@/CLink';
import { ReactComponent as EmailIcon } from '@a/icons/email.svg';
import { ReactComponent as InstagramIcon } from '@a/icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from '@a/icons/linkedin.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone-lined.svg';
import { ReactComponent as PinIcon } from '@a/icons/pin.svg';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { footerNavLinks, siteInfo } from './NavLinks';
import { h4, smTxt } from './styles/Typography';
import { flexCenter, flexStart, px, py } from './styles/classes';

const Section = styled.footer`
    ${px};
    ${py};
    padding-bottom: 32px !important;
    background-color: ${({ theme }) => theme.colors.gray6};

    > a:first-of-type {
        display: block;
        max-width: max-content;
        margin: 0 auto 32px;
        transition: opacity 0.3s ease-in-out;

        :hover,
        :focus {
            opacity: 0.8;
        }

        > .gatsby-image-wrapper {
            width: 100px;
            height: 66px;
        }
    }

    @media (min-width: 1024px) {
        > a:first-of-type {
            > .gatsby-image-wrapper {
                width: 150px;
                height: 99px;
            }
        }
    }

    @media (min-width: 1280px) {
        > a:first-of-type {
            > .gatsby-image-wrapper {
                width: 228px;
                height: 151px;
            }
        }
    }
`;

const Grid = styled.div`
    max-width: 1240px;
    margin: 0 auto;
    border-top: 1px solid ${({ theme }) => theme.colors.gray5};
    padding-top: 32px;

    @media (min-width: 1024px) {
        display: grid;
        gap: 48px;
        grid-template-columns: max-content max-content;
        justify-content: space-between;
        grid-template-areas:
            'a c'
            'b c';

        > div {
            :first-of-type {
                grid-area: a;
            }

            :nth-of-type(2) {
                grid-area: c;
                align-self: end;
                margin: 0;
            }

            :last-of-type {
                grid-area: b;
            }
        }
    }
`;

const Social = styled.div`
    > h2 {
        ${h4};
        margin: 0 0 24px;
        text-transform: none;
        color: ${({ theme }) => theme.colors.blue3};
    }

    > div {
        ${flexCenter};
        justify-content: flex-start;
        gap: 48px;

        > a {
            ${flexCenter};
            transition: opacity 0.3s ease-in-out;

            > svg {
                width: 24px;
                height: 24px;

                path {
                    transition: fill 0.3s ease-in-out;
                }
            }

            :hover,
            :focus {
                > svg path {
                    fill: ${({ theme }) => theme.colors.blue2};
                }
            }
        }
    }
`;

const Box = styled.div`
    max-width: 400px;
    margin: 40px 0;

    > h2 {
        ${h4};
        margin: 0 0 24px;
        text-transform: none;
        color: ${({ theme }) => theme.colors.gray1};
    }

    > a {
        ${flexStart};
        align-items: center;
        transition: color 0.3s ease-in-out;
        margin-bottom: 24px;
        word-break: break-word;
        max-width: max-content;
        color: ${({ theme }) => theme.colors.gray1};
        gap: 16px;

        > svg {
            flex-shrink: 0;

            path {
                transition: fill 0.3s ease-in-out;
            }
        }

        :first-of-type {
            padding-bottom: 24px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.gray4};
        }

        :last-of-type {
            margin-bottom: 0;
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.blue2};

            > svg {
                path {
                    fill: ${({ theme }) => theme.colors.gray3};
                }
            }
        }
    }
`;

const Navigation = styled.div`
    > h2 {
        ${h4};
        margin: 0 0 24px;
        text-transform: none;
        color: ${({ theme }) => theme.colors.blue3};
    }

    > div {
        display: grid;
        grid-template-columns: max-content max-content;
        gap: 16px 64px;

        > a {
            transition: color 0.3s ease-in-out;
            text-transform: capitalize;
            color: ${({ theme }) => theme.colors.gray1};

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.blue2};
            }
        }
    }
`;

const Copyright = styled.p`
    ${smTxt};
    text-align: center;
    margin: 40px 0 0;
    color: ${({ theme }) => theme.colors.gray2};
`;

export const Footer = () => {
    return (
        <Section>
            <CLink to="/">
                <StaticImage
                    src="../../../assets/images/layout/logo.png"
                    alt="city space constructors"
                    width={228}
                    height={151}
                    loading="eager"
                />
            </CLink>
            <Grid>
                <Social>
                    <h2>Find Us</h2>
                    <div>
                        <CLink to={siteInfo.social.linkedin}>
                            <LinkedinIcon />
                        </CLink>
                        <CLink to={siteInfo.social.instagram}>
                            <InstagramIcon />
                        </CLink>
                    </div>
                </Social>
                <Box>
                    <h2>Location</h2>
                    <CLink to={siteInfo.address.link}>
                        <PinIcon />
                        {siteInfo.address.text}
                    </CLink>
                    <CLink to={siteInfo.phone.link}>
                        <PhoneIcon />
                        {siteInfo.phone.text}
                    </CLink>
                    <CLink to={siteInfo.email.link}>
                        <EmailIcon />
                        {siteInfo.email.text}
                    </CLink>
                </Box>
                <Navigation>
                    <h2>Navigation</h2>
                    <div>
                        {footerNavLinks.map((data, i) => (
                            <CLink key={i} to={data.link}>
                                {data.text}
                            </CLink>
                        ))}
                    </div>
                </Navigation>
            </Grid>
            <Copyright>
                © {new Date().getFullYear()} City Space Constructors | All rights reserved.
            </Copyright>
        </Section>
    );
};
