import darkDivider from '@a/images/homepage/dark-divider.svg';
import divider from '@a/images/homepage/divider.svg';
import styled from '@emotion/styled';

const Div = styled.div<{ light?: boolean }>`
    height: 71px;
    width: 100%;
    background-image: ${({ light }) => (light ? `url(${divider})` : ` url(${darkDivider})`)};
    background-repeat: repeat-x;
    background-position: left 20px center;
`;

export const Divider = ({ light }: { light?: boolean }) => {
    return <Div light={light} />;
};
