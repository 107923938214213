import { css, Global, Theme } from '@emotion/react';
import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4, h5 } from './Typography';

export const theme: Theme = {
    colors: {
        gray1: '#FFFFFF',
        gray2: '#F2F5F7',
        gray3: '#DCE0E4',
        gray4: '#B6BABF',
        gray5: '#7A7E83',
        gray6: '#1F1F20',
        gray7: '#0A0A0A',
        blue1: '#EFF9FF',
        blue2: '#AAD7F2',
        blue3: '#0CB6FF',
        blue4: '#1094F4',
        blue5: '#2A19F0',
        blue6: '#0D24A1',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                }
                body {
                    color: ${theme.colors.gray6};
                    font-family: 'Poppins', 'Verdana', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 150%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    ${h4};
                }

                h5 {
                    ${h5};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.gray6};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                button {
                    :disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.125rem;
                    }
                }
            `}
        />
    );
};
