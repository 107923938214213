import { ReactComponent as RightArrow } from '@a/icons/right-arrow-circle.svg';
import { ReactComponent as UpArrow } from '@a/icons/up-arrow-circle.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ElementType } from 'react';
import Plain from './Plain';

const Btn = styled(Plain)<{ white?: boolean }>`
    display: inline-grid;
    grid-template-columns: fit-content max-content;
    grid-template-rows: 1fr;
    color: ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.blue5)};
    transition: color 0.3s ease-in-out;
    text-transform: capitalize;
    font-weight: 700;
    gap: 16px;
    line-height: 152.5%;
    overflow: hidden;

    > svg {
        transition:
            opacity 0.3s ease-in-out,
            transform 0.3s ease-in-out;
        grid-column: 2;
        grid-row: 1;

        :last-of-type {
            opacity: 0;
            transform: rotate(-24deg);
        }
    }

    :hover {
        color: ${({ theme, white }) => (white ? theme.colors.blue3 : theme.colors.blue6)};

        > svg {
            :first-of-type {
                opacity: 0;
                transform: rotate(24deg);
            }

            :last-of-type {
                opacity: 1;
                transform: rotate(0);
            }
        }
    }

    :focus-visible {
        color: ${({ theme, white }) => (white ? theme.colors.blue3 : theme.colors.blue6)};

        > svg {
            :first-of-type {
                opacity: 0;
                transform: rotate(24deg);
            }

            :last-of-type {
                opacity: 1;
                transform: rotate(0);
            }
        }
    }

    ${({ white, theme }) =>
        white &&
        css`
            > svg {
                :first-of-type {
                    circle {
                        stroke: ${theme.colors.gray1};
                    }

                    path {
                        fill: ${theme.colors.gray1};
                    }
                }

                :last-of-type {
                    circle {
                        stroke: ${theme.colors.blue3};
                    }

                    path {
                        fill: ${theme.colors.blue3};
                    }
                }
            }
        `};
`;

type ArrowProps = React.HTMLAttributes<HTMLButtonElement> & {
    children?: React.ReactNode;
    as?: ElementType;
    to?: string;
    white?: boolean;
};

export default function ArrowBtn({ children, ...props }: ArrowProps) {
    return (
        <Btn {...props}>
            {children} <UpArrow /> <RightArrow />
        </Btn>
    );
}
