export const siteInfo = {
    phone: {
        text: '(661) 993-2021',
        link: 'tel:+16619932021',
    },
    email: {
        text: 'info@cityspaceconstructors.com',
        link: 'mailto:info@cityspaceconstructors.com',
    },
    address: {
        text: '620 W. 135th Street, Gardena, CA 90248',
        link: 'https://maps.app.goo.gl/4b5Z8WDfPAgk4iJTA',
    },
    social: {
        linkedin: 'https://www.linkedin.com/company/cityspaceconstructors/',
        instagram: 'https://www.instagram.com/cityspaceconstructors/',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'our services',
        link: '/our-services/',
        links: [
            {
                text: 'General Contracting Services',
                link: '/our-services/general-contracting-services/',
            },
            {
                text: 'Budget Services',
                link: '/our-services/budget-services/',
            },
            {
                text: 'Preconstruction Services',
                link: '/our-services/preconstruction-services/',
            },
        ],
    },
    {
        text: 'our projects',
        link: '/our-projects/',
    },
    {
        text: 'contact us',
        link: '#contact-us',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                if (data.link) {
                    flat.push({
                        text: data.text,
                        link: data.link,
                    });
                }
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: getNavLink('about us'),
    },
    {
        text: 'our projects',
        link: getNavLink('our projects'),
    },
    {
        text: 'Our Services',
        link: getNavLink('Our Services'),
    },
    {
        text: 'Privacy Policy',
        link: '/privacy-policy/',
    },
];

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
