import styled from '@emotion/styled';

const Div = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
    border-top: 12px solid ${({ theme }) => theme.colors.blue6};
    border-bottom: 12px solid ${({ theme }) => theme.colors.blue6};
    background-color: ${({ theme }) => theme.colors.blue5};
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    > ul {
        display: flex;
        flex-shrink: 0;
        justify-content: space-around;
        animation: loopText 10s linear infinite;
        min-width: 100%;
        gap: 40px;
        margin: 0;
        padding: 0;

        @keyframes loopText {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(calc(-100% - 40px));
            }
        }

        > li {
            list-style: none;
            color: ${({ theme }) => theme.colors.gray1};
            margin: 0;
            padding: 12px 0;
        }
    }
`;

export const Marquee = () => {
    return (
        <Div>
            <ul>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
            </ul>

            <ul aria-label="hidden">
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
                <li>City Space Constructors</li>
            </ul>
        </Div>
    );
};
