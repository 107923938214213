import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactForm } from './ContactForm';
import { flexCenter } from './styles/classes';

const Section = styled.section`
    background-color: ${({ theme }) => theme.colors.gray6};

    @media (min-width: 1024px) {
        ${flexCenter};
    }
`;

export const ContactUs = () => {
    return (
        <Section>
            <StaticImage
                src="../../../assets/images/layout/contact-us.jpg"
                alt="building wall structure"
                width={659}
                height={882}
            />
            <ContactForm />
        </Section>
    );
};
